import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import algoliasearch from "algoliasearch/lite"
import {
  connectHits,
  InstantSearch,
  // Highlight,
  connectSearchBox,
} from "react-instantsearch-dom"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import CallToAction from "../components/CallToAction";
import GetQuoteForm from "../components/GetQuoteModal"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Hero = styled.div`
	padding: 150px 0 50px;
	text-align: center;

	h1 {
		font-size: 32px;
		line-height: 66px;
	}

	form {
		button {
			display: none;
		}
		input {
			width:100%;
			max-width: 800px;
			padding: 5px 20px 8px;
	    border-radius: 50px;
	    background: white;
	    box-shadow: rgb(0 0 0 / 10%) 0px 10px 30px;
	    border: 1px solid rgb(222, 222, 222);
		}
	}
`

const Result = styled.div`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  background: white;
  color: black;
  display: block;
  flex-wrap: wrap;
  text-align: left;
  & a {
    color: #333;
    text-align: left;
    &:hover {
      color: #000;
    }
  }
`

const ResultItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, .18);
  margin-bottom: 30px;

  .image-wrap {
    width: 200px;

    img {
      width: 100%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, .18);
    }
  }
  .text-wrap {
    width: calc(100% - 340px);
    margin-left: 20px;
    margin-right: 20px;

    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 5px;
      text-align: left;
      color: #0059B2;
    }

    h5{
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .button-wrap {
    width: 120px;

    a {
      cursor: pointer;
      color: #fab702;
      font-size: 16px;
    }

    button {
      cursor: pointer;
      margin-top: 10px;
      padding: 8px 12px;
      background: #fab702;
      color: white;
      border-radius: 3px;
    }
  }
  @media(max-width: 768px) {
    display: block;

    .image-wrap {
      width: 100%;

      img {
        max-width: 300px;
        margin: auto;
        margin-bottom: 30px;
      }
    }
    .text-wrap {
      width: 100%;
    }
  }
`

const SearchBox = ({ currentRefinement, isSearchStalled, refine, onChange }) => {
  return (
    <form noValidate action="" role="search">
      <input
        type="search"
        value={currentRefinement}
        onChange={event => {
          refine(event.currentTarget.value);
          onChange(event.currentTarget.value);
        }}
      />
      <button onClick={(e) => e.preventDefault()}>Reset query</button>
    </form>
  )
};

const CustomSearchBox = connectSearchBox(SearchBox);

const getDescription = desc => {
  if (!desc) return "";
  if (typeof desc === 'string') return desc;
  if (desc.metaDescription) return desc.metaDescription;
  return JSON.stringify(desc)
}

const Hits = connectHits(({ hits, product, location }) => {
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }

  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  return (
    <Result>
      {hits.length ? (
        <>
          {hits.map((hit) => {
            let title = hit.slug
            if (
              title !== "/dev-404-page/" &&
              title !== "/404.html" &&
              title != "/offline-plugin-app-shell-fallback/"
            ) {
              const pathName = title.split("/")
              let hitName = ""
              let index = pathName.length - 1
              if (pathName[index] === "") {
                hitName = pathName[index - 1]
              } else {
                hitName = pathName[index]
              }
              const temp = hitName.split("-").map(
                (item) =>
                  item.substring(0, 1).toUpperCase() + item.substring(1)
              ).join(" ");

              return (
                <div
                  key={hit.objectID}
                  style={{ display: "block", marginBottom: "5px" }}
                >
                  <ResultItem>
                    <div className="image-wrap">
                      <Link to={hit.slug}>
                        <img src={hit.pageContext.image} alt={hit.pageContext.title} />
                      </Link>
                    </div>
                    <div className="text-wrap">
                      <Link to={hit.slug}><h4>{temp}</h4></Link>
                      <h5>{hit.pageContext.title}</h5>
                      <p>{getDescription(hit.pageContext.description)}</p>
                    </div>
                    <div className="button-wrap">
                      <Link to={hit.slug}>
                        View Details
                      </Link>
                      <button onClick={HandleModalOpen}>
                        Get Quote
                      </button>
                    </div>
                  </ResultItem>
                </div>
              )
            }
          })}
        </>
      ) : (
        <p>There were no results for your query. Please try again.</p>
      )}
      <GetQuoteForm location={location} isVisible={isQuoteVisible} onClose={HandleModalClose} />
    </Result>
  )
})

const SearchPage = ({ location }) => {
  const [key, setKey] = useState("")

  useEffect(() => {
    setKey(location.state?.searchKey || "")
  }, [])

  const ctaData = {
    label: "Get in Touch",
    title: "To learn more about what AES can do for you, reach out to one of our friendly experts now!",
    linkText: '1-866-386-1001'
  }

  const handleSearch = (e) => {
    setKey(e);
  }

  return (
    <Layout>
      <Seo
        title="Search Page"
      />
      <div className="sticky-wrapper sticky">
        <Header />
      </div>
      <Hero>
        <div className="container">
          <h1>Search Result</h1>
          <InstantSearch
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
            searchClient={searchClient}
          >
            <CustomSearchBox
              defaultRefinement={key}
              translations={{
                placeholder: "Search for your need....",
              }}
              onChange={handleSearch}
            />
            {!!key && (
              <div>
                <Hits location={location} />
              </div>
            )}
          </InstantSearch>
        </div>
      </Hero>
      <CallToAction data={ctaData} />
    </Layout>
  )
}

export default SearchPage;