import React from "react"
import CloseIcon from "../Icons/CloseIcon"
import {
  Modal,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalHeader,  
  ModalBody,
  ModalBackdrop,
} from "../Modal"


class GetQuoteForm extends React.Component {
  render() {
    const { isVisible } = this.props
    return (
      <>
        <Modal
          className={"modal fade " + (isVisible ? "show" : "")}
          style={{ display: isVisible ? "block" : "none" }}
        >
          <ModalDialog className="modal-dialog modal-lg modal-dialog-centered">
            <ModalContent className="modal-content">
              <ModalHeader className="modal-header">
                <CloseButton className="close" onClick={this.props.onClose}><CloseIcon /></CloseButton>
              </ModalHeader>
              <ModalBody className="modal-body pt-0">
                <iframe src="https://www.cognitoforms.com/f/7qxSu5WtzEeoHIw8Fz5hfw/3" title="Cognito Forms"></iframe>
              </ModalBody>
            </ModalContent>
          </ModalDialog>
        </Modal>
        {isVisible && (
          <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
        )}
      </>
    )
  }
}

export default GetQuoteForm